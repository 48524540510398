$maets-green: #0d6e39;

.TechCont {
  .row {
    display: flex;
    flex-flow: row wrap-reverse;
  }
  padding: 0 20px !important;
  display: flex;
  flex-flow: row wrap;
  .techImgHover {
    max-width: 100%;
    overflow: hidden;
    .sectionText {
      position: absolute;
      z-index: 2;
      width: 100%;
      background-color: white;
      height: 45px;
      padding: 10px 10px;
      margin-top: 52px;
      font-size: 18px;
      color: $maets-green;
      font-weight: 500;
      letter-spacing: -1px;
      svg {
        overflow: hidden;
        vertical-align: middle;
        height: 1.5em;
        width: 1.5em;
        margin-left: -2px;
      }
    }
    img {
      vertical-align: middle;
      max-width: 150%;
      width: 150%;
      height: auto;
      transform: scale(1);
      transition: all 0.2s ease 0s;
    }
    img:hover {
      transform: scale(1.1);
      transition: all 0.2s ease 0s;
    }
  }
  .text {
    background-color: white;
    padding: 0;
    .techList {
      padding: 0 40px;
      li {
        font-size: 12px;
        font-weight: 300;
      }
    }
    .perf {
      h4 {
        background-color: #0d6e39;
        padding: 12px 10px;
        text-align: center;
        color: white;
        font-weight: 600;
        margin-bottom: 0;
        font-size: 1rem;
      }
      p {
        padding: 20px;
        font-size: 12px;
        margin-bottom: 0px !important;

        strong {
          font-size: 12px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .TechCont .techImgHover img {
    vertical-align: middle;
    max-width: 198%;
    width: 198%;
    height: auto;
    transform: scale(1);
    transition: all 0.2s ease 0s;
  }
  .TechCont {
    .techImgHover {
      max-width: 100%;
      overflow: hidden;
      max-height: 582px;
    }
  }
}
@media (min-width: 992px) {
  .TechCont {
    .row {
      display: flex;
      flex-flow: row wrap;
    }

    .techImgHover img {
      vertical-align: middle;
      max-width: 189%;
      width: 188%;
      height: auto;
      transform: scale(1);
      transition: all 0.2s ease 0s;
      margin-left: -49%;
    }
  }
}

@media (min-width: 1200px) {
  .TechCont {
    padding: 0 38px !important;
    display: flex;
    flex-flow: row wrap;
    .techImgHover {
      max-width: 100%;
      overflow: hidden;

      img {
        vertical-align: middle;
        max-width: 167%;
        width: 166%;
        height: auto;
        transform: scale(1);
        transition: all 0.2s ease 0s;
        margin-left: -62%;
      }
      img:hover {
        transform: scale(1.1);
        transition: all 0.2s ease 0s;
      }
    }
    .text {
      background-color: white;
      padding: 0;
      .techList {
        padding: 0 40px;
        li {
          font-size: 12px;
          font-weight: 300;
        }
      }
      .perf {
        h4 {
          background-color: #0d6e39;
          padding: 12px 10px;
          text-align: center;
          color: white;
          font-weight: 600;
          margin-bottom: 0;
        }
        p {
          padding: 20px;
          font-size: 12px;
          strong {
            font-size: 12px;
          }
        }
      }
    }
  }
}
