$maets-green: #0d6e39;
$maets-blue: #042958;

#footer {
  background-color: $maets-green;
  padding: 0 1.5rem;
  .address {
    color: white;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 0;
  }
  .col,
  .col a,
  .col .nav-link {
    color: white !important;
    padding: 0 0.25rem;
  }

  .small {
    position: relative;
    top: 3px;
  }

  .col a:hover {
    text-decoration: underline;
  }

  .copyright {
    color: white;
    padding: 9px 53px 17px 53px;
    display: flex;
    flex-flow: column wrap;
    text-align: center;

    p {
      font-size: 10px;
      line-height: 1.2;
      font-weight: 300;
      .privacyLink {
        font-size: 10px;
        font-weight: 500;
        text-decoration: none;
        color: white;
        :hover {
          color: $maets-blue;
        }
      }
    }
  }

  nav {
    padding-top: 0.25rem !important;
  }

  .mContact {
    display: flex;
    flex-flow: column wrap;
    align-items: center;

    .flexRow {
      display: flex;
      flex-flow: column nowrap;
    }
  }

  .footer_column {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    margin-right: 1rem;
  }

  .footer_main {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    padding: 1.25rem 2rem;
  }

  .footer_columns {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    flex: 1;
  }
  .footerLogos {
    display: none;
  }
  .ul_override {
    display: none;
  }

  .footer_h1 {
    display: flex;
    flex-flow: column wrap;
    font-weight: 500;
    color: white;
    font-size: 15px;
    text-decoration: none;
    align-items: center;
    text-align: center;

    p {
      margin-bottom: 6px;
    }
  }

  .footerIcon {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }

  .footer_li a {
    color: white;
    font-weight: 300;
    list-style-type: none;
    font-size: 13px;
  }

  .hrFooter {
    border: 1px solid white;
    width: 100%;
    margin: 5px 0 18px 0;
  }
  img {
    max-width: 305px;
  }
  .img-fluid {
    max-width: 100px;
    height: auto;
    position: inherit;
  }
  .footerLogos {
    text-align: center;
    justify-content: center;
  }
}
.privacyHeader {
  padding-top: 100px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
  img {
    max-width: 300px;
  }
}
.privacyBody {
  display: flex;
  flex-flow: column wrap;
  padding-top: 20px;
  h2,
  .h2 {
    font-weight: 600;
    text-align: center;
    text-decoration: underline;
  }
  p,
  ul {
    font-size: 11px;
    padding: 0 28px;
  }
  li,
  strong,
  a,
  u,
  em {
    font-size: 11px;
  }
  h6,
  .h6 {
    font-size: 15px;
    font-weight: 600;
    padding: 0 28px;
    text-decoration: underline;
  }
}

@media (min-width: 768px) {
  #footer {
    .footer_main {
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
      padding: 1.25rem 11rem;
    }
    .mContact {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      max-width: 100%;
    }
  }
}
@media (min-width: 992px) {
  #footer {
    .address {
      margin-bottom: 0;
      padding-left: 23px;
      font-weight: 300;
    }
    .copyright {
      color: white;
      padding: 12px 0 0 0;
      display: flex;
      flex-flow: column wrap;
      background-color: #549872;
      p {
        font-size: 12px;
        line-height: 1;
      }
    }
    .footer_h1 {
      font-weight: 500;
      color: white;
      font-size: 15px;
      text-decoration: none;
      align-items: baseline;
      padding-bottom: 0;
      text-align: left;
    }
    .hrFooter {
      border: 1px solid white;
      width: 50%;
      margin: 21px 0;
    }

    .footer_main {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      padding: 1.75rem 3rem;
    }
    .footer_column {
      margin-right: 0;
    }
    .footer_columns {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      flex: 1 1;
    }
    .footerIcon {
      display: flex;
      flex-flow: row nowrap;
      justify-content: initial;
    }
    .ul_override {
      display: block;
      list-style-type: none;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 0;
    }

    .col {
      flex-basis: none;
    }

    .col,
    a,
    .nav-link {
      a.contactUs {
        font-size: 18px;
        font-weight: 700;
        margin-top: 10px;
        text-decoration: none;
      }
    }
    .row {
      flex-flow: column wrap;
    }

    img {
      max-width: 305px;
      padding-top: 0;
      margin-left: 0;
    }
    .img-fluid {
      max-width: 100px;
      height: auto;
      position: inherit;
    }
    .footerLogos {
      display: flex;
      flex-flow: row wrap;
      text-align: left;
    }
    .mContact {
      align-items: flex-start;
    }
  }
  .privacyHeader {
    padding-top: 150px;
  }
}

@media (min-width: 1200px) {
  #footer {
    .address {
      margin-bottom: 0;
      padding-left: 23px;
      font-weight: 300;
    }
    .copyright {
      color: white;
      padding: 12px 0 0 0;
      display: flex;
      flex-flow: column wrap;
      background-color: #549872;
      p {
        font-size: 12px;
        line-height: 1;
      }
    }
    .footer_h1 {
      font-weight: 500;
      color: white;
      font-size: 15px;
      text-decoration: none;
      align-items: baseline;
      padding-bottom: 0;
      text-align: left;
    }
    .hrFooter {
      border: 1px solid white;
      width: 50%;
      margin: 21px 0;
    }

    .footer_main {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      padding: 0.75rem 6rem 3rem;
    }
    .footer_column {
      margin-right: 0;
    }
    .footer_columns {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      flex: 1 1;
    }
    .footerIcon {
      display: flex;
      flex-flow: row nowrap;
      justify-content: initial;
    }
    .ul_override {
      display: block;
      list-style-type: none;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 0;
    }

    .col {
      flex-basis: none;
    }

    .col,
    a,
    .nav-link {
      a.contactUs {
        font-size: 18px;
        font-weight: 700;
        margin-top: 10px;
        text-decoration: none;
      }
    }
    .row {
      flex-flow: column wrap;
    }

    img {
      max-width: 305px;
margin-top: -10px;
    margin-bottom: -7px;
    }
    .mContact {
      align-items: flex-start;
    }
  }
}
