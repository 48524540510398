$maets-green: #0d6e39;

.jobListing {
  margin-top: 77px;
  .jobRow {
    margin: 50px;
  }
  .card {
    margin-left: none;
    width: 100%;

    .card-header {
      color: $maets-green;
      font-size: 23px;

      span {
        font-size: 23px;
        font-weight: 700;
      }
    }
    .card-subtitle {
      margin-top: 1rem;
      margin-left: 20px;
      font-weight: 300;
      span {
        font-weight: 700;
        color: $maets-green;
      }
    }
    .card-text {
      margin-top: 1rem;
      margin-left: 20px;
      margin-right: 20px;
      font-weight: 300;
      span {
        font-weight: 700;
        color: $maets-green;
      }
      ul {
        margin-left: 25px;
      }
    }
    .card-footer {
      .btn {
        text-align: center;
        width: 100%;
        background-color: $maets-green;
        border-color: $maets-green;
        color: white;
        font-size: 30px;
        font-weight: 400;
        :hover {
          background-color: white;
          color: $maets-green;
        }
      }
    }
  }
}

// @media (min-width: 576px) {
// }
@media (min-width: 992px) {
  .jobListing {
    margin-top: 111px;
  }
}

@media (min-width: 1200px) {
  .jobListing {
    margin-top: 141px;

    .card-header {
      color: $maets-green;
      font-size: 23px;
      span {
        font-size: 23px;
      }
    }
    .card-subtitle {
      margin-top: 1rem;
      margin-left: 20px;
      font-weight: 300;
      span {
        font-weight: 700;
        color: $maets-green;
      }
    }
    .card-text {
      margin-top: 1rem;
      margin-left: 20px;
      font-weight: 300;
      span {
        font-weight: 700;
        color: $maets-green;
      }
    }
    .card-footer {
      .btn {
        text-align: center;
        width: 600px;
        background-color: $maets-green;
        border-color: $maets-green;
        color: white;
        font-size: 30px;
        font-weight: 400;
        :hover {
          background-color: white;
          color: $maets-green;
        }
      }
    }
  }
}
