.contact-section {
  background-color: var(--white);
  width: 100%;
}

@media screen and (min-width: 799px) {
  .contact-section {
    --offset-height: -60px;

    display: grid;
    grid-template-columns: 3fr 1.5fr;
    width: 80vw;
    margin: 0 auto;
    top: var(--offset-height);
    left: 50%;
    margin-left: -40vw;
    margin-bottom: var(--offset-height);
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.15);
  }
}
@media screen and (min-width: 992px) {
  .contact-section {
    --offset-height: -127px;

    display: grid;
    grid-template-columns: 3fr 1.5fr;
    width: 80vw;
    margin: 0 auto;
    top: var(--offset-height);
    left: 50%;
    margin-left: -40vw;
    margin-bottom: var(--offset-height);
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.15);
  }
}
