$maets-green: #0d6e39;
#services {
  width: 100%;
  height: 100%;
}
#servicesTopWrapper {
  padding-top: 100px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}
#servicesHeroWrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}
#servicesHeroText {
  display: flex;
  position: absolute;
  background: $maets-green;
  z-index: 2;
  box-shadow: none;
  // top: 40px;
  // padding-left: 168px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 5px;
  line-height: 1;
  .liTitle {
    display: none;
    // font-size: 18px;
    // color: white !important;
    // line-height: 1.5;
    // padding-top: 5px;
    // padding-bottom: 2px;
    // width: 18%;
    // font-weight: 700;
  }
  svg {
    display: none;
  }

  .servicesNavs {
    // display: none;
    margin-bottom: 0;
    list-style-type: none;
    display: flex;
    flex-flow: row wrap;
    height: auto;
    justify-content: space-evenly;
    width: 100%;

    li {
      // display: none;
      text-align: center;
      color: white;
      font-size: 11px;
      font-weight: 500;
      color: white;
      align-self: center;

      a {
        // padding: 0px 45px;
        text-decoration: none;
        font-size: 12px;
        font-weight: 300;
        color: white;
        line-height: 2;

        &:hover {
          cursor: pointer;
          background-color: transparent;
          color: white;
          font-weight: 500;
        }
      }
    }
  }
}

.breaker {
  background-color: $maets-green;
  color: white;
  height: auto;
  box-shadow: 0 0 8px black;
  font-size: 22px;
  font-weight: 600;
  padding: 19px 30px;
  svg {
    overflow: hidden;
    vertical-align: text-bottom;
    height: 1.5em !important;
    width: 1.5em !important;
    margin-left: -7px;
  }
}

.engImg {
  border-radius: 10px;
  margin-top: 15px;
  box-shadow: -3px 4px 8px 0px #00000075;
}

.engWrapper {
  display: flex;
  flex-flow: column wrap;
  padding: 20px 50px 30px 50px;
  .engListWrapper {
    background-color: white;
    margin: 10px;
    border-radius: 10px;
    box-shadow: -3px 4px 8px 0px #00000075;
  }
}
.engineeringList {
  padding: 0px 14px;
  margin-bottom: 0;
  text-align: left;

  li {
    list-style-type: disc;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
  }
  .circle {
    color: black;
    font-size: 11px;
    font-weight: 500;
    list-style-type: circle;
    margin-left: 40px;
  }
}

.gs_reveal h4 {
  font-size: 20px;
}
// .perfConf {
//   width: auto;
//   box-shadow: -3px 4px 8px 0px #00000075;
//   margin-top: 18px;
//   border-radius: 10px;

//   h4 {
//     background-color: $maets-green;
//     padding: 12px 10px;
//     font-size: 16px;
//     text-align: center;
//     color: white;
//     font-weight: 600;
//     margin-bottom: 0;
//     border-top-right-radius: 10px;
//     border-top-left-radius: 10px;
//   }
//   p {
//     padding: 20px 25px;
//     font-size: 14px;
//     background-color: white;
//     margin-bottom: 0;
//     border-bottom-right-radius: 10px;
//     border-bottom-left-radius: 10px;
//   }
// }

.techWrapper {
  display: flex;
  flex-flow: column wrap;
  padding: 20px 50px 30px 50px;

  .techListWrapper {
    background-color: white;
    margin: 10px;
    border-radius: 10px;
    box-shadow: -3px 4px 8px 0px #00000075;

    .techList {
      padding: 21px 32px;
      margin-bottom: 0;

      li {
        list-style-type: disc;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
      }
    }
  }
}

@media (min-width: 576px) {
  #servicesHeroWrapper {
    justify-content: normal;

    #servicesHeroText {
      display: block;
      top: 40px;
      padding-left: 95px;
      padding-top: 0;
      padding-bottom: 0;
      line-height: 1;

      // .liTitle {
      //   display: revert;
      //   color: white;
      //   font-size: 22px;
      //   padding-left: 76px;
      //   line-height: 1.5;
      //   padding-top: 5px;
      //   padding-bottom: 2px;

      //   svg {
      //     display: revert;
      //     height: 1em !important;
      //     width: 1em !important;
      //   }
      //}
      .servicesNavs {
        display: none;
      }
    }
  }
}
@media (min-width: 768px) {
  #servicesTopWrapper {
    padding-top: 190px;
  }
  #servicesHeroWrapper {
    justify-content: flex-end;

    #servicesHeroText {
      display: block;
      top: 40px;
      padding-left: 10px;
      padding-top: 2px;
      padding-bottom: 0;
      // .liTitle {
      //   display: revert;
      //   color: white;
      //   font-size: 22px;
      //   font-weight: 700;
      //   padding-left: 225px;
      //   svg {
      //     display: revert;
      //     height: 1em !important;
      //     width: 1em !important;
      //   }
      // }
      .servicesNavs {
        display: flex;
        flex-flow: row wrap;
        padding-top: 0;
        line-height: 1;
        justify-content: center;

        li {
          display: block;
          text-align: center;
          color: white;
          font-size: 11px;
          font-weight: 500;
          color: white;
          a {
            padding: 0px 14px;
            text-decoration: none;
            font-size: 12px;
            font-weight: 300;
            color: white;

            &:hover {
              cursor: pointer;
              background-color: transparent;
              color: #042958;
            }
          }
        }
      }
    }
  }

  .engWrapper {
    display: flex;
    flex-flow: row wrap;
    .engImg {
      .img-fluid {
        width: 40%;
        height: 40%;
      }
    }
  }
  .techWrapper {
    display: flex;
    flex-flow: row wrap;
  }
}

@media (min-width: 1078px) {
  #servicesHeroText {
    display: flex;
    flex-flow: row nowrap;
    padding-left: 10px;
    padding-bottom: 0;
    .liTitle {
      display: block;
      color: white;
      font-size: 20px;
      font-weight: 700;
      width: 20%;
      align-self: center;

      svg {
        display: revert;
        height: 1em !important;
        width: 1em !important;
      }
    }
    .servicesNavs {
      padding: 5px 0;
      line-height: 1;
      justify-content: space-evenly;

      li {
        display: block;
        text-align: center;
        color: white;
        font-size: 11px;
        font-weight: 500;
        color: white;
        a {
          // padding: 0px 14px;
          text-decoration: none;
          font-size: 12px;
          font-weight: 300;
          color: white;

          &:hover {
            cursor: pointer;
            background-color: transparent;
            color: white;
            font-weight: 500;
          }
        }
      }
    }
  }

  .engWrapper,
  .techWrapper {
    padding: 20px 23px 20px 40px;
    background-color: white;
  }
}
@media (min-width: 1200px) {
  #servicesTopWrapper {
    padding-top: 200px;
    #servicesHeroWrapper {
      justify-content: flex-end;
    }
  }
  .engWrapper,
  .techWrapper {
    padding: 20px 23px 20px 40px;
    background-color: white;
  }
  .perfConf {
    margin-top: 9px;
  }
}
