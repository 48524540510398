$maets-green: #0d6e39;
$maets-blue: #042958;

#careers {
  background: url('./images/career_hero1.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
}
#careerTopWrapper {
  padding-top: 100px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  // padding-right: 20px !important;
  // padding-left: 20px !important;
}

.careerFlash {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}

#careerHeroWrapper {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;

  #careerHeroText {
    display: flex;
    position: absolute;
    background: $maets-green;
    z-index: 2;
    box-shadow: none;
    top: 40px;
    padding-left: 168px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 5px;
    line-height: 1;
    .careerTitle {
      display: revert;
      font-size: 27px;
      font-weight: 700;
      color: white;
      line-height: 1.5;
    }
  }
}
.careerHexWrap {
  display: flex;
  top: 15px;
  filter: drop-shadow(0 0 9px #000000);
  padding-bottom: 15px;
  margin: 0 43px;

  .careerHex {
    width: 330px;
    height: 140px;
    background-color: white;
    border-radius: 10px;
    margin: 16px 0px;
  }

  .careerHex_seeking {
    position: absolute;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #695d5d;
    top: 31px;
    padding: 0 0px 0 78px;
    font-size: 11px;
    font-weight: 500;
    // width: 75%;
    span {
      font-weight: 700;
      line-height: 0;
    }
  }
  #pacmanCareer {
    width: 0px;
    height: 0px;
    border-right: 49px solid transparent;
    border-top: 70px solid #0d6e39;
    border-left: 31px solid #0d6e39;
    border-bottom: 70px solid #0d6e39;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    position: absolute;
    margin: 16px 321px 0px 0px;
    z-index: 2;
  }
  .hexLearnMore {
    position: absolute;
    text-align: left;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: $maets-green;
    background-color: #b1f345;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: auto;
    top: 126px;
    // z-index: 2;
    padding: 4px 63px 2px 88px;
    font-weight: 600;
    font-size: 16px;
    width: 330px;
    svg {
      height: 12px;
      width: 12px;
      margin-bottom: 2px;
    }
  }
  .hexLearnMore a {
    color: $maets-green;
    text-decoration: none;
  }
  .hexLearnMore a:hover {
    color: $maets-blue;
  }
}

#jobs {
  float: right;
  display: flex;
  flex-flow: column nowrap;
  margin: 0 22px;
}
.jobAvailCard {
  width: 100% !important;
  float: right;
}

.jobCard {
  // margin: 14px 83px;
  // margin: 30px 31px;
  width: 100%;

  .jobAvailability {
    font-size: 13px !important;
    font-weight: 600 !important;
    color: $maets-green;
    padding: 10px;
    text-transform: uppercase;
  }
  .card-header {
    font-size: 17px;
    font-weight: 400;
    span {
      font-size: 17px;
      font-weight: 700;
    }
    a {
      font-size: 17px;
      font-weight: 500;
      color: $maets-green;
      text-decoration: none;
    }
    a:hover {
      color: $maets-blue;
    }
  }
  .card-body {
    padding: 0px 24px 10px;
  }
  .card-title {
    margin-bottom: 0.75rem;
    font-size: 17px;
    font-weight: 200;
    .h5,
    h5 {
      font-size: 17px !important;
    }
    span {
      font-size: 17px;
      font-weight: 500;
    }
  }
  .card-text {
    font-size: 13px;
    font-weight: 400;
    span {
      font-size: 14px;
      font-weight: 600;
    }
    p {
      font-size: 13px;
      font-weight: 400;
      b,
      strong {
        font-size: 13px;
      }
    }
  }
  .jobLink {
    a {
      text-decoration: none;
      font-size: 14px;
      font-weight: 700;
      color: $maets-green;
    }
    a:hover {
      color: $maets-blue;
    }
  }
  .card-footer {
    display: flex;
    justify-content: space-between;
    .btn {
      background-color: $maets-green;
      color: white;
      border: 2px solid transparent;
      outline: none;
    }
    .btn:hover {
      background-color: white;
      color: $maets-green;
      border: 2px solid $maets-green;
      outline: none;
    }
    .btn:active {
      background-color: $maets-green !important;
      color: white !important;
      border: 2px solid transparent !important;
      outline: none !important;
    }
    .btn:focus {
      background-color: $maets-blue;
      color: white;
      border: 2px solid transparent;
      outline: none !important;
      box-shadow: 0 0 0 0.2rem transparent;
    }
  }
}
.eeoStatement {
  padding: 20px 20px 20px;
  text-align: center;
  font-size: 13px;
  background-color: white;
}
// @media (min-width: 325px) {
//   .careerHexWrap {
//     padding: 0px 3px;
//     top: 31px;
//     .careerHex_seeking {
//       width: 77%;
//       font-size: 10px;
//     }
//   }
// }

@media (min-width: 576px) {
  #jobs {
    align-items: flex-end;
    width: 500px;
  }
  .careerHexWrap {
    // justify-content: left;
    padding-left: 28px;
    text-align: left;
    // padding-right: 28px;
    margin: 0;

    .careerHex {
      width: 500px;
      margin: 16px 23px;
    }

    .careerHex_seeking {
      top: 15px;
      padding: 15px 0px 0px 85px;
      font-size: 13px;
      font-weight: 500;
      width: 68%;
      color: #695d5d;
    }

    .hexLearnMore {
      // text-align: center;
      padding: 6px 63px 4px 88px;
      top: 122px;
      width: 500px;

      svg {
        width: 1em;
        height: 1em;
        margin-left: 10px;
      }
    }
  }
}

@media (min-width: 768px) {
  #careerHeroWrapper #careerHeroText {
    padding-left: 239px;
  }
  .careerHexWrap {
    justify-content: right;
    padding-left: 28px;
    text-align: left;
    margin: 0;
    .careerHex {
      width: 500px;
    }
    #pacmanCareer {
      margin: 16px 444px 0px 0px;
    }
    .careerHex_seeking {
      top: 15px;
      padding: 15px 0px 0px 85px;
      font-size: 13px;
      font-weight: 500;
      width: 63%;
      color: #695d5d;
    }

    .hexLearnMore {
      // text-align: center;
      padding: 6px 63px 4px 88px;
      top: 122px;

      svg {
        width: 1em;
        height: 1em;
        margin-left: 10px;
      }
    }
  }
  .eeoStatement {
    padding: 20px 135px 20px;
    text-align: center;
    font-size: 13px;
    background-color: white;
  }
}

@media (min-width: 992px) {
  #careerTopWrapper {
    padding-top: 144px;
    padding-right: 30px !important;
    padding-left: 30px !important;
  }
  #careerHeroWrapper #careerHeroText {
    top: 60px;
    padding-left: 250px;
    padding-top: 7px;
    padding-bottom: 5px;
    line-height: 1.5;
    .careerTitle {
      font-size: 32px;
      svg {
        height: 1.5em;
        width: 1.5em;
      }
    }
  }
  .careerHexWrap {
    // justify-content: right;
    padding-left: 28px;
    text-align: left;
    // .careerHex {
    //   width: 600px;
    // }

    .careerHex_seeking {
      top: 15px;
      padding: 26px 49px 0px 85px;
      font-size: 13px;
      font-weight: 500;
      width: 577px;
    }

    .hexLearnMore {
      // text-align: center;
      padding: 6px 63px 4px 88px;
      top: 122px;

      svg {
        width: 1em;
        height: 1em;
        margin-left: 10px;
      }
    }
  }
}

@media (min-width: 1200px) {
  #careerTopWrapper {
    padding-top: 144px;
    padding-right: 50px !important;
    padding-left: 50px !important;
  }
  #careerHeroWrapper #careerHeroText {
    padding-left: 250px;
    .careerTitle {
      font-size: 32px;
      svg {
        height: 1.5em;
        width: 1.5em;
      }
    }
  }
  .careerHexWrap {
    justify-content: right;
    // padding-right: 79px;
    text-align: left;
    // .careerHex {
    //   width: 502px;
    // }

    .careerHex_seeking {
      top: 15px;
      padding: 26px 49px 0px 85px;
      font-size: 13px;
      font-weight: 500;
      width: 577px;
    }

    .hexLearnMore {
      // text-align: center;
      padding: 6px 63px 4px 88px;
      top: 122px;

      svg {
        width: 1em;
        height: 1em;
        margin-left: 10px;
      }
    }
  }
  .jobCard {
    // margin: 14px 83px;
    .card-header {
      font-size: 17px;
      font-weight: 400;
      span {
        font-size: 17px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    .jobLink {
      a {
        text-decoration: none;
        font-size: 14px;
        font-weight: 700;
        color: $maets-green;
      }
    }
    .card-footer {
      .btn {
        background-color: $maets-green;
        color: white;
        border: 2px solid transparent;
        outline: none;
      }
      .btn:hover {
        background-color: white;
        color: $maets-green;
        border: 2px solid $maets-green;
        outline: none;
      }
      .btn:active {
        background-color: $maets-green !important;
        color: white !important;
        border: 2px solid transparent !important;
        outline: none !important;
      }
      .btn:focus {
        background-color: $maets-blue;
        color: white;
        border: 2px solid transparent;
        outline: none !important;
        box-shadow: 0 0 0 0.2rem transparent;
      }
    }
  }
}
