* {
  margin: 0;
  border: 0;
  padding: 0;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
}
html {
  overflow-x: hidden;
}

$grid-columns: 12;
$grid-gutter-width: 1.5rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

:root {
  --main-blue: #091540;
  --main-green: #0d6e39;
  --white: #ffffff;
}

.scrollTop {
  position: fixed;
  width: 100%;
  bottom: 425px;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  // animation: $fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
  right: 10px;
}

.scrollTop:hover {
  opacity: 1;
}

// @keyframes fadeIn {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 0.5;
//   }
// }
