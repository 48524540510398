/* Based on styles from the GreenSock website */
/*Coded screen to mobile size */

body {
  font-weight: 300;
}
.ipsType_right {
  text-align: left;
}
.ipsType_center {
  text-align: center;
}
.cInnerContent {
  margin-left: 50px;
  margin-right: 50px;
}
.cP {
  font-size: 12px !important;
}
.ipsSpacer_bottom_double {
  margin-bottom: 30px;
}
.ipsGrid {
  display: inline-block;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.ipsGrid::before,
.ipsGrid::after {
  display: table;
  content: '';
  line-height: 0;
}
.ipsGrid > [class*='ipsGrid_span'] {
  display: block;
  width: 100%;
  min-height: 30px;
  box-sizing: border-box;
}
.ipsGrid > .ipsGrid_span5 {
  width: 40.42553191489362%;
  min-width: 300px;
}
.ipsGrid > .ipsGrid_span7 {
  width: 57.44680851063829%;
}
.ipsGrid > [class*='ipsGrid_span'] {
  float: left;
  margin-left: 2%;
}
.ipsGrid > [class*='ipsGrid_span']:first-child {
  margin-left: 0;
}
.feature {
  display: flex;
  align-items: flex-start;
}
.feature1 {
  display: flex;
  align-items: flex-start;
}
.card {
  margin-bottom: 30px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 10px 3px #242323c7;
  /*   transition: 0.3s; */
}
.featured-image-container .card {
  padding: 10px;
  height: 0;
  padding-bottom: calc(100% - 10px);
}
h2.heading_large {
  font-size: 1.8em;
  font-weight: 700;
  color: #0d6e39;
}
img {
  max-width: 100%;
}

.header-section {
  margin: 150px 0px 100px;
}
.header-section > h1 > span {
  font-weight: 700;
  font-size: 2.5rem;
  color: #0d6e39;
}

.gs_reveal {
  opacity: 0;
  visibility: hidden;
  will-change: transform, opacity;
}
.gs_reveal h4 {
  font-size: 1.5rem;
  font-weight: 700;
}
.caret {
  transform: rotate(180deg);
  width: 2em;
  height: 2em;
}

@media (max-width: 992px) {
  h2.heading_large {
    font-size: 1.5em;
  }
  .gs_reveal h4 {
    font-size: 1.2rem;
  }

  .cP {
    font-size: 11px !important;
  }

  .header-section {
    margin: 126px 0 100px;
  }
  .ipsGrid {
    justify-content: center;
  }
  .ipsGrid > .ipsGrid_span5 {
    width: 55%;
  }
  .ipsGrid > .ipsGrid_span7 {
    width: 100%;
  }
  .feature1 {
    display: flex;
    align-items: flex-start;
    flex-flow: row wrap-reverse;
  }
}

@media (max-width: 576px) {
  .header-section {
    margin: 126px 0 123px;
  }
  .caret {
    width: 1em;
  }
  h2.heading_large {
    font-size: 1.1em;
  }
  .gs_reveal h4 {
    font-size: 1rem;
  }
  br {
    display: none;
  }
  .ipsGrid > [class*='ipsGrid_span'] {
    margin-left: 0;
  }
}

@media (max-width: 407px) {
  .header-section {
    margin: 98px 0 142px;
  }
}
