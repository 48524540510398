$maets-green: #0d6e39;
$maets-blue: #042958;

// css built mobile size and up
#header {
  top: 0;
  z-index: 9999;
  overflow: visible;
  box-shadow: 0 0 17px black;
  padding: 0;
  width: 100%;
  position: fixed;

  .arrow-right {
    width: 0;
    height: 0;
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-left: 40px solid white;
  }
  nav {
    font-size: 18px;
    padding-right: 30px;
  }

  .navbar {
    padding: 0 0 !important;
    height: 40px;
    background-color: white;
  }

  .navbar-brand {
    padding: 0 0;
    display: flex;
    flex-flow: row nowrap;
    z-index: 1000;
    height: 80px;
  }
  .nav-item {
    margin-right: 0px;
  }
  .nav-logo {
    position: relative;
    width: 130px;
    background-color: white;
    box-shadow: -11px 3px 8px 0px #0000005e;
  }

  .navbar-nav {
    text-align: end;
    // padding-bottom: 36px;
  }

  .navbar-toggler {
    padding: 0.25rem 1.75rem;
    margin-bottom: 41px;
    color: white;
    border: none;
  }
  .nav-link,
  .navbar-text {
    color: white !important;
    font-size: 15px;
    font-weight: 500;
    margin-right: 18px;
  }

  .nav-link:hover {
    text-decoration: none;
    color: $maets-blue !important;
  }

  #collasible-nav-dropdown {
    overflow: visible;
  }

  //Toggle icon from sandwich to X
  .dropdown-menu {
    box-shadow: -3px 3px 6px -2px #00000091;
    top: 96% !important;
    min-width: 11rem !important;
    background-color: $maets-green !important;
    border: 0 !important;
    color: white !important;
    text-align: right;
    padding: 0 0 !important;
  }

  .dropdown-item {
    color: #ffffffab !important;
    letter-spacing: 0.6px !important;
    font-weight: 300 !important;
    padding-right: 30px;
    transition: 0.5s padding-right ease;

    &:focus {
      background-color: $maets-blue !important;
      color: white !important;
      padding-right: 35px;
    }
    &:hover {
      background-color: white !important;
      color: black !important;
      padding-right: 35px;
    }
    &:active {
      background-color: $maets-blue !important;
      color: white !important;
      padding-right: 35px;
    }
  }
  .active {
    background-color: $maets-blue !important;
    color: white;
    &:active {
      background-color: $maets-blue !important;
      color: white !important;
    }
  }
  .nav-controls {
    align-items: center;
  }
  .dropdown-menu::before {
    display: none;
  }
  .dropdown-menu::after {
    display: none;
  }

  /* remove navbar button stying */
  .navbar-toggler {
    color: black;
    border: none;
    margin-bottom: 40px;

    /* remove the blue outline when active or focused */
    &:active,
    &:focus {
      outline: 0;
    }

    /* basic styles for each icon bar */
    .icon-bar {
      background: white;
      display: block;
      width: 22px;
      height: 2px;
      border-radius: 1px;
      margin: 4px 0 4px 0;
      transition: all 0.2s;

      /* custom .navbar-dark .icon-bar background */
      .navbar-dark & {
        background: #ffffff;
      }

      /* .navbar open top .icon-bar rotated down 45° */
      &:nth-of-type(1) {
        transform: rotate(45deg);
        transform-origin: 10% 10%;
      }

      /* .navbar open middle .icon-bar invisible */
      &:nth-of-type(2) {
        opacity: 0;
        filter: alpha(opacity=0);
      }

      /* .navbar open bottom .icon-bar rotated up 45° */
      &:nth-of-type(3) {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
      }
    }

    /* styles for when .navbar is closed */
    &.collapsed {
      .icon-bar {
        /* .navbar closed top .icon-bar no rotation - straight */
        &:nth-of-type(1) {
          transform: rotate(0);
        }

        /* .navbar open middle .icon-bar visible */
        &:nth-of-type(2) {
          opacity: 1;
          filter: alpha(opacity=100);
        }

        /* .navbar open bottom .icon-bar no rotation - straight */
        &:nth-of-type(3) {
          transform: rotate(0);
        }
      }
    }
    .navbar-nav {
      margin: 7px auto;
      // background: linear-gradient(
      //   90deg,
      //   rgba(255, 255, 255, 1) 22%,
      //   $maets-green 75%
      // );
      padding-right: 20px;
    }
    .nav-link {
      margin-bottom: 3px;
    }
  }
  .navbar-expand-xl .navbar-collapse {
    // padding-left: 102px;
    z-index: 100;
    background-color: $maets-green;
    margin-top: -40px;
  }

  .navbar-collapse {
    text-align: end;
    justify-content: flex-end;
    .dropdown-menu {
      box-shadow: none;
      .dropdown-item {
        text-align: right;
        font-size: 15px;
        // padding: 0.25rem 18px 0.25rem 0;
      }
    }
  }

  #borderLeft {
    display: none;
  }
}
// ****** 576px  // sm

@media (min-width: 576px) {
  #header {
    .navbar-brand {
      height: 80px;
    }
    .navbar-expand-xl .navbar-collapse {
      margin-top: -40px;
      z-index: 100;
      background-color: $maets-green;
    }
  }
  .col {
    padding-right: 0px;
  }

  .nav-logo {
    position: relative;
    width: 150px;
    vertical-align: middle;
  }
  .navbar-nav {
    margin: 19px auto 0;
  }
  .navbar-collapse {
    text-align: end;
    justify-content: flex-end;
    .dropdown-menu {
      box-shadow: none;
      .dropdown-item {
        text-align: right;
        font-size: 15px;
        padding: 0.25rem 0;
      }
    }
  }
}

//****** 768px // md

@media (min-width: 768px) {
  #header {
    .navbar-brand {
      height: 80px;
    }
    .navbar-nav {
      margin: 5px auto;
    }
    .nav-logo {
      position: relative;
      width: 200px;
      vertical-align: middle;
      border: 1px solid white;
    }
  }
  .navbar-collapse {
    text-align: end;
    justify-content: flex-end;
    .dropdown-menu {
      box-shadow: none;
      .dropdown-item {
        text-align: right;
        font-size: 15px;
        padding: 0.25rem 0;
      }
    }
  }
}
@media (min-width: 992px) {
  #header {
    .arrow-right {
      width: 0;
      height: 0;
      border-top: 60px solid transparent;
      border-bottom: 60px solid transparent;
      border-left: 60px solid white;
    }
    .navbar {
      padding: 0 0 !important;
      height: 60px;
      background-color: transparent;
    }
    .navbar-brand {
      height: 120px;
    }
    .navbar-expand-xl .navbar-collapse {
      margin-top: -60px;
    }
  }
  .navbar-collapse {
    text-align: end;
    justify-content: flex-end;
    .dropdown-menu {
      box-shadow: none;
      .dropdown-item {
        text-align: right;
        font-size: 15px;
        padding: 0.25rem 0;
      }
    }
  }
}

//*****1200px // xl
@media (min-width: 1200px) {
  #header {
    position: fixed;
    width: 100%;

    .navbar-brand {
      height: 120px;
    }
    .arrow-right {
      width: 0;
      height: 0;
      border-top: 60px solid transparent;
      border-bottom: 60px solid transparent;
      border-left: 60px solid white;
    }
    .navbar-collapse .dropdown-menu .dropdown-item {
      padding: 0.25rem 15px;
      text-align: left;
    }
    .navbar {
      padding: 0 0 !important;
      height: 60px;
      background-color: none;
    }
    .navbar-expand-xl {
      flex-flow: wrap;
      justify-content: revert;
    }

    .navbar-expand-xl .navbar-collapse {
      margin-top: -37px;
      z-index: 100;
      background-color: transparent;
    }
    .nav-logo {
      position: relative;
      width: 200px;
      vertical-align: middle;
    }

    nav {
      font-size: 1.1rem !important;
      padding-right: 0;
    }

    #borderLeft {
      height: 40px;
      width: 2px;
      background: white;
    }
  }
}
