$maets-green: #0d6e39;
$maets-blue: #042958;
#about {
  background: url('./images/ship5.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
}

#aboutTopWrapper {
  top: 200px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
}

#aboutSubNavWrapper {
  color: white;
  display: flex;
  justify-content: center;
}

#abtHeroWrapper {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;

  #aboutHeroText {
    position: absolute;
    background: $maets-green;
    z-index: 2;
    box-shadow: none;
    top: 40px;
    padding-left: 174px;
    padding-top: 5px;
    padding-bottom: 2px;
    padding-right: 5px;
    display: flex;

    .aboutTitle {
      margin-bottom: 0;
      list-style-type: none;
      display: flex;
      flex-flow: row wrap;
      height: auto;
      justify-content: space-evenly;

      .liTitle {
        display: flex;
        font-size: 22px;
      }
      svg {
        display: inline;
        position: relative;
        margin-top: 9px;
        margin-left: -7px;
      }
      li {
        text-align: center;
        color: white;
        font-size: 11px;
        font-weight: 500;
        color: white;
        display: none;
        a {
          padding: 0px 0px;
          text-decoration: none;
          font-size: 10px;
          font-weight: 300;
          color: white;
          line-height: 2;

          &:hover {
            cursor: pointer;
            background-color: transparent;
            color: #042958;
          }
        }
      }
    }
  }
}

.aboutWrapper {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding-top: 80px;

  .background {
    color: black;
    margin: 26px 8%;
    border: 3px solid white;
    padding: 13px 7px;
    display: flex;
    flex-flow: row wrap;
    background-color: white;
    border-radius: 8px;
    text-align: center;
    box-shadow: -4px 5px 6px #00000057;
  }
  p {
    font-size: 13px;
    font-weight: 300;
    text-align: left;
    margin-bottom: 0rem;
  }
  img {
    max-width: 160px;
    padding-top: 5px;
  }
  .iso {
    font-size: 12px;
    padding: 0 10px;
  }
  .backgroundImages {
    text-align: center;
  }
}

#leadership {
  h3 {
    color: green;
    font-weight: 600;
    font-size: 1.25rem;
  }
}

.leadershipBreaker {
  background-color: $maets-green;
  color: white;
  height: 50px;
  box-shadow: 0 0 8px black;
  font-size: 18px;
  font-weight: 600;
  padding: 12px 26px;
  svg {
    overflow: hidden;
    vertical-align: middle;
    height: 1.5em !important;
    width: 1.5em !important;
    margin-left: -7px;
  }
  span {
    font-size: 28px;
    font-weight: 300;
  }
}

.card-deck {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 30px 87px !important;

  .card {
    margin-bottom: 30px !important;
    min-width: 238px;
    max-width: 238px;
    border-radius: 5px;
    box-shadow: -4px 5px 6px #00000057;
  }
  .card-body {
    padding: 1rem;
  }
  .card-img-top {
    height: 200px;
    background-color: lightslategray;
  }
  .userCircle {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 5px;
  }
  .card-footer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }

  p {
    font-size: 12px;
    a {
      font-size: 12px;
    }
  }

  .h5,
  h5 {
    font-size: 1rem !important;
  }

  .icon {
    text-decoration: none;
    color: #6c757d;
    &:hover {
      color: $maets-green;
    }
  }
  .sub-title {
    color: $maets-green;
  }
  .h6,
  h6 {
    font-size: 0.9rem;
  }
}

#teamPartners {
  background-color: #ebeef5;
  display: flex;
  flex-flow: row wrap;
  padding: 28px 0;
  margin-right: 0;
  margin-left: 0;
  text-align: center;
  box-shadow: 0px 4px 7px #000000a6;

  .col {
    min-width: 350px;
    ul {
      margin-bottom: 0 !important;
    }
  }

  li {
    list-style: none;
    font-size: 14px;
    line-height: 2;
    font-weight: 500;
    a {
      color: black;
      text-decoration: none;
      font-size: 14px;
      line-height: 2;
      font-weight: 500;

      &:hover {
        color: $maets-green;
      }
    }
  }
}
.teamTitle {
  background-color: $maets-green;
  color: white;
  height: 50px;
  box-shadow: 0 0 8px black;
  font-size: 20px;
  font-weight: 600;
  padding: 11px 55px;
  svg {
    overflow: hidden;
    vertical-align: middle;
    height: 1.5em;
    width: 1.5em;
    margin-left: -7px;
  }
}
.naicsBreaker {
  background-color: $maets-green;
  color: white;
  height: 70px;
  margin-top: 54px;
  box-shadow: 0 0 8px black;
  font-size: 20px;
  font-weight: 600;
  padding: 8px 10px;
  text-align: center;
  svg {
    display: none;
    overflow: hidden;
    vertical-align: middle;
    height: 2em !important;
    width: 2em !important;
    margin-left: -7px;
  }
  span {
    font-size: 15px;
    font-weight: 300;
    display: block;
  }
}

.naicsWrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 24px 30px 0;
  background-color: #ebeef5;
  box-shadow: 0px 5px 9px 0px #0000007a;

  .col {
    min-width: 290px;
    ul {
      margin-bottom: 0 !important;
    }
  }

  li {
    list-style: none;
    font-size: 14px;
    padding-bottom: 18px;
    padding-right: 10px;
  }
  strong {
    font-size: 14px;
    color: #07612f;
  }
}

@media (min-width: 576px) {
  #aboutTopWrapper {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
  }
  .aboutWrapper {
    padding-top: 87px;
    .background {
      text-align: center;
    }
    p {
      font-size: 14px;
    }
    img {
      max-width: 160px;
    }
  }
  #abtHeroWrapper {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;

    #aboutHeroText {
      display: flex;
      position: absolute;
      background: $maets-green;
      z-index: 2;
      box-shadow: none;
      top: 40px;
      padding-left: 138px;
      padding-top: 10px;
      padding-bottom: 8px;

      .aboutTitle {
        margin-bottom: 0;
        list-style-type: none;
        display: flex;
        flex-flow: row wrap;
        height: auto;
        justify-content: space-around;

        .liTitle {
          display: initial;
          padding: 0px 0 0 41px;
          line-height: 1;
        }
        svg {
          display: inline;
          position: relative;
          margin-top: 0px;
          margin-left: -7px;
        }
        li {
          text-align: center;
          color: white;
          font-size: 11px;
          font-weight: 500;
          color: white;
          a {
            padding: 0px 8px;
            text-decoration: none;
            font-size: 11px;
            font-weight: 300;
            color: white;
            line-height: 2;

            &:hover {
              cursor: pointer;
              background-color: transparent;
              color: #042958;
            }
          }
        }
      }
    }
  }
  .leadershipBreaker {
    background-color: $maets-green;
    color: white;
    height: 50px;
    box-shadow: 0 0 8px black;
    font-size: 20px;
    font-weight: 600;
    padding: 11px 55px;
  }
}

@media (min-width: 768px) {
  #aboutTopWrapper {
    justify-content: normal;
  }
  .aboutWrapper {
    padding-top: 100px;
    p {
      font-size: 14px;
    }
    img {
      max-width: 130px;
    }
  }
  #abtHeroWrapper {
    flex-flow: row wrap;

    #aboutHeroText {
      position: absolute;
      background: $maets-green;
      z-index: 2;
      box-shadow: none;
      top: 40px;
      padding-left: 210px;

      .aboutTitle {
        margin-bottom: 0;
        list-style-type: none;
        display: flex;
        flex-flow: row wrap;
        height: auto;
        svg {
          display: inline;
        }
        li {
          text-align: center;
          color: white;
          font-size: 15px;
          font-weight: 500;
          color: white;
          a {
            padding: 0px 15px;
            text-decoration: none;
            font-size: 15px;
            font-weight: 300;
            color: white;
            line-height: 2;

            &:hover {
              cursor: pointer;
              background-color: transparent;
              color: #042958;
            }
          }
        }
      }
    }
  }
  .aboutHeroImage {
    max-width: none !important;
    height: auto;
  }
  .leadershipBreaker {
    background-color: $maets-green;
    color: white;
    height: 50px;
    box-shadow: 0 0 8px black;
    font-size: 20px;
    font-weight: 600;
    padding: 11px 107px;
  }
  .teamTitle {
    background-color: $maets-green;
    color: white;
    height: 50px;
    box-shadow: 0 0 8px black;
    font-size: 20px;
    font-weight: 600;
    padding: 11px 134px;
  }
}

@media (min-width: 992px) {
  #aboutTopWrapper {
    justify-content: normal;
  }
  #abtHeroWrapper {
    flex-flow: row wrap;

    #aboutHeroText {
      position: absolute;
      background: $maets-green;
      z-index: 2;
      box-shadow: none;
      top: 60px;
      padding-left: 237px;
      padding-top: 21px;
      padding-bottom: 17px;

      .aboutTitle {
        margin-bottom: 0;
        list-style-type: none;
        display: flex;
        flex-flow: row wrap;
        height: auto;
        li {
          text-align: center;
          color: white;
          font-size: 18px;
          font-weight: 500;
          color: white;
          a {
            padding: 0px 26px;
            text-decoration: none;
            font-size: 18px;
            font-weight: 300;
            color: white;

            &:hover {
              cursor: pointer;
              background-color: transparent;
              color: #042958;
            }
          }
        }
      }
    }

    .leadershipBreaker {
      svg {
        overflow: hidden;
        vertical-align: middle;
        height: 2em !important;
        width: 2em !important;
        margin-left: -7px;
      }
    }
  }
  .aboutHeroImage {
    max-width: none !important;
    height: auto;
  }
  .aboutWrapper {
    padding-top: 150px;

    img {
      max-width: 155px;
    }
  }
}

@media (min-width: 1200px) {
  #aboutTopWrapper {
    display: flex;
  }

  #abtHeroWrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: normal;

    #aboutHeroText {
      position: absolute;
      background: $maets-green;
      z-index: 2;
      box-shadow: none;
      top: 60px;
      padding-left: 237px;

      .aboutTitle {
        margin-bottom: 0;
        list-style-type: none;
        display: flex;
        flex-flow: row wrap;
        height: auto;
        li {
          text-align: center;
          color: white;
          font-size: 18px;
          font-weight: 500;
          color: white;

          a {
            padding: 0px 40px;
            text-decoration: none;
            font-size: 18px;
            font-weight: 300;
            color: white;

            &:hover {
              cursor: pointer;
              background-color: transparent;
              color: #042958;
            }
          }
        }
      }
    }
  }
  .aboutWrapper {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    padding-top: 150px;

    .background {
      color: black;
      margin: 33px 11%;
      border: 3px solid white;
      padding: 29px 14px;
      display: flex;
      flex-flow: row wrap;
      background-color: white;
      border-radius: 8px;
      text-align: start;
    }
    p {
      font-size: 14px;
      font-weight: 300;
      text-align: left;
    }
    img {
      max-width: 180px;
      padding-top: 35px;
    }
    .iso {
      font-size: 15px;
      padding: 0 10px;
    }
  }
  #leadership {
    margin-right: 0;
    left: 10px;
    h3 {
      color: green;
      font-weight: 600;
    }
  }

  .leadershipBreaker {
    background-color: $maets-green;
    color: white;
    height: 50px;
    box-shadow: 0 0 8px black;
    font-size: 20px;
    font-weight: 600;
    padding: 11px 134px;
    svg {
      overflow: hidden;
      vertical-align: text-top;
      height: 1.5em;
      width: 1.5em;
      margin-left: -7px;
    }
    span {
      font-size: 28px;
      font-weight: 300;
    }
  }
  #teamPartners {
    background-color: #ebeef5;
    display: flex;
    flex-flow: row wrap;
    padding: 10px 158px;
    text-align: start;
    box-shadow: 0px 4px 7px #000000a6;

    .col {
      min-width: 350px;
      padding-left: 33px;
      padding-top: 0;
      margin-top: 10px;
      ul {
        margin-bottom: 0 !important;
      }
    }
  }

  .teamTitle {
    background-color: $maets-green;
    color: white;
    height: 50px;
    box-shadow: 0 0 8px black;
    font-size: 20px;
    font-weight: 600;
    padding: 11px 134px;
    svg {
      overflow: hidden;
      vertical-align: text-top;
      height: 1.5em;
      width: 1.5em;
      margin-left: -7px;
    }

    li {
      list-style: none;
      font-size: 18px;
      line-height: 2;
      font-weight: 500;

      a {
        color: black;
        text-decoration: none;
        font-size: 18px;
        line-height: 2;
        font-weight: 500;

        &:hover {
          color: $maets-green;
        }
      }
    }
  }
  .naicsBreaker {
    background-color: $maets-green;
    color: white;
    height: 93px;
    margin-top: 54px;
    box-shadow: 0 0 8px black;
    font-size: 20px;
    font-weight: 600;
    padding: 15px 163px;
    svg {
      overflow: hidden;
      vertical-align: middle;
      height: 2em !important;
      width: 2em !important;
      margin-left: -7px;
    }
    span {
      font-size: 20px;
      font-weight: 300;
    }
  }

  .naicsWrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 24px 150px 0;
    background-color: #ebeef5;
    box-shadow: 0px 5px 9px 0px #0000007a;

    .col {
      min-width: 290px;
    }

    li {
      list-style: none;
      font-size: 14px;
      padding-bottom: 18px;
      padding-right: 10px;
    }
    strong {
      font-size: 14px;
      color: #07612f;
    }
  }
}
