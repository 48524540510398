.info {
  text-align: left;
  padding: 20px;
  background-color: var(--main-green);
  color: var(--white);
}

.info-h2 {
  text-transform: uppercase;
  font-weight: 500;
  margin: 0;
}

.info-details-container {
  margin: 23px 0;
}

.info-detail {
  font-weight: 400;
}

.info-detail a {
  text-decoration: none;
  color: white !important;
}

.info-icons-container {
  display: flex;
  width: 100%;
}

.info-icon {
  font-size: 3rem;
  margin-right: 20px;
  cursor: pointer;
}

@media screen and (min-width: 699px) {
  .info {
    display: flex;
    flex-direction: column;
    padding: 40px;
  }

  .info-detail {
    line-height: 2;
  }
}
