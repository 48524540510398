$maets-green: #0d6e39;
$maets-blue: #042958;

#pacman {
  width: 0px;
  height: 0px;
  border-right: 187px solid #0d6e39;
  border-top: 47px solid #0d6e39;
  border-left: 188px solid #0d6e39;
  border-bottom: 109px solid transparent;
}

.backgroundHome {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  .back {
    max-width: 400px !important;
  }
  .h3 {
    padding-top: 20px;
    padding-left: 10px;
    color: $maets-green;
    font-weight: 700;
    font-size: 18px;
  }
  p {
    padding-left: 10px;
    font-size: 12px;
  }
  .homeBackgroundImages {
    justify-content: center;
    text-align: center;
    img {
      max-width: 175px;
    }
    .iso {
      font-size: 12px;
    }
  }
}
.backgroundHomeWrap {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 30px !important;
  height: 30px !important;
  filter: drop-shadow(0 0 5px black);
}

.carousel {
  display: none;
  position: relative;
  top: 120px;

  .carousel-indicators {
    li {
      width: 15px;
      height: 15px;
      margin-right: 16px;
      margin-left: 16px;
      border-radius: 50%;
      filter: drop-shadow(0 0 5px black);
    }
  }
}

.carousel-button {
  border: 1px solid white;
  padding: 20px;
}

.carousel-caption {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  bottom: 121px !important;
  background-color: #ffffff4a;
  border-radius: 10px;
}

.hex {
  position: relative;

  .hex-title {
    width: 100vw;
    height: 40px;
    background: $maets-green;
    position: absolute;
    margin: auto;
    top: 40px;
    z-index: 2;
    font-size: 26px;
    font-weight: 300;
    color: #fff;
    line-height: 40px;
    text-align: right;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-decoration: none;
    padding-right: 6px;

    a {
      color: white;
      font-size: 26px;
      font-weight: 300;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-decoration: none;
      &:hover {
        font-weight: 500;
        text-decoration: none;
        color: white;
      }
    }
  }

  .hex-img {
    width: 100vw;
    height: 100%;
    position: relative;
    overflow: hidden;
    box-shadow: 0 -11px 38px black;
  }
  .hex-img img {
    max-height: 100%;
  }
}

.hexagonWrap {
  display: none;
}
.splashText {
  color: black;
  position: absolute;
  top: 82px;
  z-index: 4;
  padding: 12px 15px 6px 163px;
  background-color: transparent;
  text-align: right;
  width: 100%;
  p {
    font-size: 17px;
    font-weight: 600;
    line-height: 1;
    margin-bottom: 7px;
    filter: drop-shadow(0 0 2px white);
    text-shadow: 0 0 6px white;
  }
}

.mobileView {
  display: flex;
  flex-flow: column wrap;
}

@media (min-width: 438px) {
  .hex .hex-img img {
    max-width: 100vw;
    max-height: none;
  }
}

@media (min-width: 576px) {
  #pacman {
    width: 0px;
    height: 0px;
    border-right: 114px solid transparent;
    border-top: 150px solid $maets-green;
    border-left: 40px solid $maets-green;
    border-bottom: 150px solid $maets-green;
  }

  .backgroundHome {
    padding: 28px 0;
    justify-content: left;
    .col-md-4 {
      width: 75%;
    }
    .h3 {
      padding-top: 20px;
      color: $maets-green;
      font-weight: 700;
    }
    .homeBackgroundImages {
      justify-content: center;
      text-align: center;
      width: 50%;
      img {
        max-width: 175px;
      }
    }
  }
  .backgroundHomeWrap {
    display: flex;
    flex-flow: row wrap;
  }

  .splashText {
    color: black;
    top: 60px;
    padding: 10px 32px 6px 0;
    text-align: end;

    p {
      font-size: 22px;
      filter: drop-shadow(0 0 3px white);
    }
  }

  .homeCarousel {
    padding: 0 !important;
    height: 500px;
    .carousel-inner {
      height: 500px;
    }
  }
  .carousel {
    display: revert;
    position: relative;
    top: 0;
    .carousel-indicators {
      margin-bottom: 2rem;

      li {
        width: 13px !important;
        height: 13px !important;
        margin-right: 16px !important;
        margin-left: 16px !important;
      }
    }
  }
  .mobileView {
    display: none;
  }
  .servicesMore {
    position: absolute;
    right: 6px;
    text-align: right;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: black;
    margin: auto;
    top: 320px;
    z-index: 2;
    padding: 9px 32px;
    display: flex;
    flex-flow: row wrap;
    width: 570px;
    justify-content: flex-end;
    .p {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 7px;
      border-bottom: 1px solid;
      text-shadow: 0 0 3px white, 0 0 3px white;
    }
    .btn-outline-dark {
      margin-top: 8px;
      font-weight: 600;
      color: black;
      border: 1px solid black;
      background-color: rgba(255, 255, 255, 0.658);
      box-shadow: -2px 2px 7px #00000061;
    }
    .btn-outline-dark:hover {
      color: white;
      background-color: $maets-green !important;
      border-color: $maets-green;
    }
  }
  .learnMore {
    position: absolute;
    right: 0;
    text-align: right;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: black;
    margin: auto;
    top: 95px;
    z-index: 2;
    padding: 9px 32px;
    display: flex;
    flex-flow: row wrap;
    width: 487px;
    justify-content: flex-end;
    .p {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 7px;
      border-bottom: 1px solid;
      text-shadow: 0 0 3px white;
    }
    .p1 {
      color: black;
      font-size: 10px;
      font-weight: 500;
      margin-bottom: 0px;
      width: 288px;
      letter-spacing: 1px;
      text-shadow: 0 0 3px white, 0 0 3px white;
    }

    .btn-outline-dark {
      margin-top: 8px;
      font-weight: 500;
      color: black;
      border: 1px solid black;
      background-color: rgba(255, 255, 255, 0.658);
      box-shadow: -2px 2px 7px #00000061;
    }
    .btn-outline-dark:hover {
      color: white;
      background-color: $maets-green !important;
      border-color: $maets-green;
    }

    .p3 {
      color: black;
      font-size: 10px;
      font-weight: 700;
      margin-bottom: 0px;
      width: 255px;
      letter-spacing: 1px;
      text-shadow: 0 0 3px white, 0 0 3px white;
    }
  }
}

@media (min-width: 768px) {
  #pacman {
    width: 0px;
    height: 0px;
    border-right: 114px solid transparent;
    border-top: 150px solid $maets-green;
    border-left: 40px solid $maets-green;
    border-bottom: 150px solid $maets-green;
  }

  .backgroundHome {
    padding: 28px 0;
    .col-md-4 {
      width: 500px;
      flex: none;
      max-width: 100%;
    }
    .h3 {
      padding-top: 20px;
      color: $maets-green;
      font-weight: 700;
    }
    .homeBackgroundImages {
      justify-content: center;
      text-align: center;
      img {
        max-width: 175px;
      }
    }
  }
  .backgroundHomeWrap {
    display: flex;
    flex-flow: row wrap;
  }

  .mobileView {
    display: none;
  }

  .splashText {
    color: black;
    position: absolute;
    top: 65px;
    z-index: 4;
    padding: 8px 31px 0px 0;
    text-align: right;
    width: 100%;
    p {
      font-size: 27px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 5px;
      filter: drop-shadow(0 0 3px white);
    }
  }
  .carousel {
    display: revert;
    position: relative;
    top: 0;
    .carousel-indicators {
      margin-bottom: 3rem;

      li {
        width: 15px !important;
        height: 15px !important;
        margin-right: 16px !important;
        margin-left: 16px !important;
      }
    }
  }

  .learnMore {
    position: absolute;
    right: 0;
    text-align: right;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: black;
    margin: auto;
    top: 115px;
    z-index: 2;
    padding: 9px 32px;
    display: flex;
    flex-flow: row wrap;
    width: 487px;
    justify-content: flex-end;
    .p {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 7px;
      border-bottom: 1px solid;
      text-shadow: 0 0 3px white;
    }
    .p1 {
      color: black;
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 0px;
      width: 300px;
      letter-spacing: 1px;
      text-shadow: 0 0 3px white, 0 0 3px white;
    }
    .p3 {
      color: black;
      font-size: 10px;
      font-weight: 700;
      margin-bottom: 0px;
      width: 255px;
      letter-spacing: 1px;
      text-shadow: 0 0 3px white, 0 0 3px white;
    }
    .btn {
      margin-top: 8px;
      background-color: rgba(255, 255, 255, 0.658);
    }
  }
  .homeCarousel {
    padding: 0 !important;
    height: 500px;
    .carousel-inner {
      height: 500px;
    }
  }
}
@media (min-width: 849px) {
  .y-move {
    transform: translateY(-26%);
  }
}

@media (min-width: 992px) {
  .backgroundHome .col-md-4 {
    width: 825px;
  }
  .backgroundHome .back {
    max-width: 100% !important;
  }

  .splashText {
    top: 60px;
    padding: 16px 23px 9px 0;
    text-align: end;

    p {
      font-size: 32px;
      filter: drop-shadow(0 0 3px white);
    }
  }

  .carousel {
    position: relative;
    top: 0;

    .carousel-indicators {
      margin-bottom: 2rem;

      li {
        width: 15px !important;
        height: 15px !important;
        margin-right: 16px !important;
        margin-left: 16px !important;
      }
    }
  }

  .learnMore {
    position: absolute;
    right: 0;
    text-align: right;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: black;
    margin: auto;
    top: 125px;
    z-index: 2;
    padding: 9px 32px;
    display: flex;
    flex-flow: row wrap;
    width: 487px;
    justify-content: flex-end;
    .p {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 7px;
      border-bottom: 1px solid;
      text-shadow: 0 0 3px white, 0 0 3px white;
    }
    .p1 {
      color: black;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0px;
      width: 320px;
      letter-spacing: 1px;
      text-shadow: 0 0 3px white, 0 0 3px white;
    }
    .btn {
      margin-top: 8px;
      background-color: rgba(255, 255, 255, 0.658);
    }
  }
  .homeCarousel {
    padding: 0 !important;
    height: 600px;
    .carousel-inner {
      height: 600px;
    }
  }
  .servicesMore {
    top: 419px;
  }
}
@media (min-width: 1200px) {
  #pacman {
    width: 0px;
    height: 0px;
    border-right: 114px solid transparent;
    border-top: 150px solid $maets-green;
    border-left: 40px solid $maets-green;
    border-bottom: 150px solid $maets-green;
  }

  .backgroundHome {
    padding: 28px 0;
    justify-content: flex-start;
    .col-md-3 {
      width: 66%;
      padding-right: 8px;
      padding-left: 0px;
    }

    .h3 {
      padding-top: 20px;
      color: $maets-green;
      font-weight: 700;
    }
    .homeBackgroundImages {
      justify-content: center;
      text-align: center;
      img {
        max-width: 175px;
      }
    }
  }
  .backgroundHomeWrap {
    display: flex;
    flex-flow: row wrap;
  }

  .carousel {
    position: relative;
    top: 0;

    .carousel-indicators {
      margin-bottom: 1rem;
      li {
        width: 15px !important;
        height: 15px !important;
        margin-right: 16px !important;
        margin-left: 16px !important;
        border-radius: 50% !important;
        filter: drop-shadow(0 0 5px black);
      }
    }
  }

  .carousel-button {
    border: 1px solid white;
    padding: 20px;
  }

  .carousel-caption {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    bottom: 121px !important;
    background-color: #ffffff4a;
    border-radius: 10px;
  }

  .splashText {
    color: black;
    position: absolute;
    top: 42px;
    z-index: 4;
    padding: 70px 32px 0px 0;
    background-color: transparent;
    text-align: end;
    width: 100%;
    p {
      font-size: 34px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 11px;
      filter: drop-shadow(0 0 3px white);
    }
  }

  .learnMore {
    position: absolute;
    right: 0;
    text-align: right;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: black;
    margin: auto;
    top: 160px;
    z-index: 2;
    padding: 9px 32px;
    display: flex;
    flex-flow: row wrap;
    width: 492px;
    justify-content: flex-end;
    .p {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 7px;
      border-bottom: 2px solid;
      text-shadow: 0 0 3px white, 0 0 3px white;
    }
    .p1 {
      color: black;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0px;
      width: 392px;
      letter-spacing: 1px;
      text-shadow: 0 0 3px white, 0 0 3px white;
    }
    .p3 {
      width: 346px;
      font-size: 12px;
    }

    .btn {
      margin-top: 8px;
      background-color: rgba(255, 255, 255, 0.658);
    }
  }

  .homeCarousel {
    padding: 0 !important;
    height: 600px;
    .carousel-inner {
      height: 600px;
    }
  }
}
