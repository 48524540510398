$maets-green: #0d6e39;
$maets-blue: #042958;

.intro-emp {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $maets-green;
  color: white;
  padding: 39px 13% 73px;
}

.intro-emp-p {
  margin-top: -16px;
  margin-bottom: 0;
  font-size: 29px;
  font-weight: 700;
}

.imgEmp {
  max-width: 300px;
  padding-top: 28px;
  margin-left: 0;
}

.emp-sect {
  display: flex;
  width: 71vw;
  margin: 0 auto;
  top: -54px;
  left: 14%;
  margin: 0 auto 0px 0px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.15);
}

.empList {
  text-align: center;
  color: $maets-green;
  padding: 30px 0 0;
  ul {
    list-style-type: none;
    li {
      padding-bottom: 1em;

      a {
        text-decoration: none;
        color: $maets-green;
      }
      a:hover {
        color: $maets-blue;
        text-decoration: none;
      }
    }
  }
}

@media screen and (min-width: 699px) {
  .imgEmp {
    max-width: 350px;
    padding-top: 6px;
    margin-left: 0;
  }

  .intro-emp-p {
    margin-bottom: 0;
    font-size: 35px;
    font-weight: 700;
  }

  .emp-sect {
    display: flex;
    width: 46vw;
    margin: 0 auto;
    top: -54px;
    left: 29%;
    margin: 0 auto 0px 0px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.15);
  }
}

@media screen and (min-width: 800px) {
  .emp-sect {
    display: flex;
    width: 34vw;
    margin: 0 auto;
    top: -54px;
    left: 33%;
    margin: 0 auto 0px 0px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.15);
  }
}

@media (min-width: 992px) {
  .imgEmp {
    padding-top: 0px;
  }

  .intro-emp {
    padding: 73px 13% 60px;
  }

  .emp-sect {
    margin: 0 auto 160px 0px;
  }
}

@media screen and (min-width: 1200px) {
  .intro-emp {
    padding: 73px 13% 50px;
  }

  .intro-emp-p {
    margin-bottom: 1rem;
    font-size: 45px;
    font-weight: 700;
  }
}
