$maets-green: #0d6e39;

#contracts {
  background: url('./images/Carousel3.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
}
#contractsWrapper {
  padding-top: 117px;
  display: flex;
  flex-flow: row wrap;
  #contractsHeroWrapper {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;

    #contractsHeroText {
      display: flex;
      position: absolute;
      background: $maets-green;
      z-index: 2;
      box-shadow: none;
      top: 40px;
      padding-left: 168px;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 5px;
      line-height: 1;
      .contractsTitle {
        display: revert;
        font-size: 22px;
        font-weight: 700;
        color: white;
        line-height: 1.85;
      }
    }
  }
}
.contractsBreaker {
  height: 50px;
  background-color: $maets-green;
  align-content: center;
  margin-bottom: 32px;
  width: 100%;

  h2 {
    text-align: center;
    color: white;
    padding-top: 13px;
    font-weight: 600;
    font-size: 23px;
  }
}
.subBreaker {
  height: 40px;
  background-color: $maets-green;
  color: white;
  font-size: 15px;
  font-weight: 600;
  padding: 11px 31px;
  margin-bottom: 0px;
  text-align: center;
}

.contractsBaseWrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  background-color: white;
  padding-bottom: 25px;
  margin-bottom: 75px;

  ol,
  ul {
    padding-left: 15px;
    li {
      font-size: 12px;
    }
  }

  p {
    font-weight: 600;
    font-size: 12px;
    span {
      font-weight: 400;
      font-size: 12px;
    }
  }

  .row {
    width: 88%;
  }

  .pocTitle {
    margin-bottom: 0;
    padding: 7px 0px 6px 11px;
    list-style-type: none;
    background-color: $maets-green;
    color: white;
    font-weight: 700;
    font-size: 16px;
  }
  .card {
    max-width: inherit;
    p {
      margin: 10px;
      font-size: 14px;
    }
  }

  .card-title {
    margin: 10px;
    font-size: 16px;
  }
  .card-body {
    padding: 0 !important;
  }

  .card-footer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }
  .contractTitle {
    font-size: 14px;
    font-weight: 800;
    padding-top: 25px;
  }
  .icon {
    text-decoration: none;
    color: #6c757d;
    &:hover {
      color: $maets-green;
    }
  }
  .sub-title {
    color: $maets-green;
    margin: 10px;
    font-size: 14px;
  }

  .table-responsive-lg {
    padding: 0 10px;
    .td {
      font-weight: 500;
    }
  }
}

.contractsBaseWrapperA {
  padding: 34px 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  background-color: #f1eeee;
  border-top: 4px dotted #c2c2c2;
  border-bottom: 4px dotted #c2c2c2;
  margin-bottom: 75px;

  ol,
  ul {
    padding-left: 15px;
    li {
      font-weight: 400;
      font-size: 12px;
    }
  }
  p {
    font-weight: 600;
    font-size: 12px;

    span {
      font-weight: 400;
      font-size: 12px;
    }
  }

  span {
    font-size: 12px;
  }

  .row {
    width: 88%;
  }

  .pocTitle {
    margin-bottom: 0;
    padding: 7px 0px 6px 11px;
    list-style-type: none;
    background-color: $maets-green;
    color: white;
    font-weight: 700;
    font-size: 16px;
  }
  .card {
    max-width: 255px;
    p {
      margin: 10px;
      font-size: 14px;
    }
  }

  .card-title {
    margin: 10px;
    font-size: 16px;
  }
  .card-body {
    padding: 0 !important;
    font-size: 14px;
  }

  .card-footer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }
  .contractTitle {
    font-size: 14px;
    font-weight: 800;
  }
  .icon {
    text-decoration: none;
    color: #6c757d;
    &:hover {
      color: $maets-green;
    }
  }
  .sub-title {
    color: $maets-green;
    margin: 10px;
  }

  .table-responsive-lg {
    padding: 0 10px;
    .td {
      font-weight: 500;
    }
  }
}

.documents {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  max-width: 255px;
  word-wrap: break-word;
  background-color: #ffffff;
  border-radius: 0.25rem;
  margin-top: 30px;
  padding: 10px;
  border: 0.5px solid #13b258;
  .svg-inline--fa {
    margin-right: 10px;
    margin-top: 2px;
  }
}

.contractsCompletedTitle {
  font-size: 21px;
  font-weight: 600;
  color: $maets-green;
  p {
    font-size: 12px;
    color: black;
    padding-top: 15px;
    margin-bottom: 0;
  }
  span {
    font-weight: 400;
    color: black;
    font-size: 12px;
  }
}

.contractsSubWrap {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
}

@media (min-width: 576px) {
  #contractsWrapper {
    padding-top: 200px;
  }

  .contractsBreaker h2 {
    padding-left: 70px;
    text-align: left;
  }
  .subBreaker {
    height: 58px;
    padding: 11px 60px;
    margin-bottom: 75px;
  }
}

.table td,
.table th {
  font-size: 12px;
}
@media (min-width: 768px) {
  #contractsWrapper #contractsHeroWrapper #contractsHeroText {
    padding-left: 237px;
  }
  .contractsBreaker h2 {
    padding-left: 74px;
    text-align: left;
  }
  .subBreaker {
    height: 52px;
    padding: 15px 75px;
    margin-bottom: 0px;
    text-align: left;
    font-size: 18px;
  }
}

@media (min-width: 992px) {
  #contractsWrapper {
    padding-top: 187px;

    #contractsHeroWrapper #contractsHeroText {
      top: 60px;
      padding-left: 210px;
      padding-top: 3px;
      padding-bottom: 0px;
      .contractsTitle {
        font-size: 31px;
        svg {
          width: 1.5em;
          height: 1.5em;
        }
      }
    }
  }
  .contractsBaseWrapper .card {
    margin-top: 25px;
    max-width: 255px;
  }
  .contractsBreaker h2 {
    padding-left: 100px;
    text-align: left;
  }
  .subBreaker {
    padding: 11px 86px;
  }
}

@media (min-width: 1200px) {
  #contractsWrapper #contractsHeroWrapper #contractsHeroText {
    padding-left: 250px;
  }
  .contractsBaseWrapper .card {
    margin-top: 25px;
  }
  .contractsBreaker h2 {
    padding-left: 100px;
    text-align: left;
  }
  .subBreaker {
    padding: 11px 100px;
  }
}
