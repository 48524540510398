/* $maets-green: #0d6e39;
$maets-blue: #042958; */
.form-group,
.contact-form {
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  max-width: 100%;
  padding: 10px 20px;
  margin-bottom: 0 !important;

  .form-group:last-of-type {
    align-items: flex-end;
  }

  .form-group .btn,
  .form-group button {
    color: #0d6e39 !important;
    font-weight: 500 !important;
    background-color: white;
  }

  .form-group .btn:hover {
    color: #042958 !important;
  }

  .form-h2 {
    margin: 0;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: 600;
    color: #0d6e39 !important;
    padding-top: 10px;
  }

  .form-label {
    margin: 10px 0;
    text-align: left;
    width: 100%;
    color: #0d6e39 !important;
    font-weight: 500 !important;
  }

  .form-input,
  .form-textarea {
    display: block;
    margin-top: 5px;
    font-size: 1rem;
    width: calc(100% - 20px);
    border: 1px solid var(--main-blue);
    border-radius: 2px;
    padding: 10px;
  }

  .form-textarea {
    height: 250px;
  }

  .form-textarea::placeholder {
    font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 400;
  }

  .form-submit {
    justify-self: end;
    font-size: 1rem;
    font-weight: 600;
    color: #0d6e39;
    background-color: white;
  }

  .form-submit svg {
    font-size: 4rem;
  }

  .form-submit:hover {
    color: #042958;
  }
  .submitButton {
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
  }
}
@media screen and (min-width: 799px) {
  .form-h2 {
    text-align: left;
  }

  .form-label {
    margin: 0;
  }

  #message {
    height: 100%;
  }

  .form-textarea {
    height: 175px;
  }

  .form-submit {
    grid-area: submit;
  }
}
