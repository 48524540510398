.intro-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--main-blue);
  color: var(--white);
  height: 40vh;
  padding: 38% 10% 16% 10%;
}

.intro-h2 {
  margin: 0;
}

.intro-p {
  max-width: 85vw;
}
/* 
@media screen and (min-width: 699px) {
  .intro-container {
    justify-content: start;
    align-items: flex-start;
    text-align: left;
    padding: 20% 10% 23% 10%;
    height: 25vh;
  }

  .intro-p {
    max-width: 800px;
  }
} */

@media (min-width: 576px) {
  .intro-container {
    padding: 30% 10% 16% 10%;
  }

}

@media screen and (min-width: 768px) {
  .intro-container {
    justify-content: start;
    align-items: flex-start;
    text-align: left;
    padding: 13% 10% 22% 10%;
    height: 34vh;
  }
}

@media screen and (min-width: 799px) {
}
@media (min-width: 992px) {
  .intro-container {
    padding: 14% 10% 24% 10%;

  }
}

@media screen and (min-width: 1200px) {
  .intro-container {
    justify-content: start;
    align-items: flex-start;
    text-align: left;
    padding: 12% 10% 20% 10%;
    height: 21vh;
  }

  .intro-p {
    max-width: 800px;
  }
}
