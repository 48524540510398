$maets-green: #0d6e39;
$maets-blue: #042958;
.carousel_A {
  background-color: white;
  padding: 15px 0 25px;
}

.slider-wrapper {
  width: 100vw;
  margin: auto;
}
.carouselTitle {
  display: none;
}
// .slick-slider {
//   position: initial;
// }
.slick-slide {
  text-align: center;
  position: relative;
}
.slick-slide:focus {
  outline: none;
}
.slick-slide-title {
  text-transform: uppercase;
  font-weight: 600;
  color: $maets-green;
}
.slick-slide-image {
  width: 100vw;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25),
    0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
}
.slick-slide-label {
  color: #fff;
  padding: 10px;
  position: absolute;
  left: 0px;
  font-size: 1.5em;
  bottom: 0px;
  width: 100%;
}
.slick-prev:before,
.slick-next:before {
  color: #777777;
}
.thumbnail-slider-wrap {
  margin-top: 15px;
  height: 85px;
}
.thumbnail-slider-wrap .slick-track .slick-slide {
  text-align: center;
}
.thumbnail-slider-wrap .slick-track .slick-slide img {
  width: 70%;
}

.slick-prev,
.slick-next {
  display: none !important;
}

.slick-dots {
  width: 100vw;
}

@media (min-width: 576px) {
  .carousel_A {
    background-color: white;
    padding: 15px 0 105px;
  }

  .carouselTitle {
    display: block;
    background-color: #0d6e39;
    color: white;
    height: 49px;
    margin-top: 54px;
    box-shadow: 0 0 8px black;
    font-size: 20px;
    font-weight: 600;
    padding: 10px 10px;
    text-align: center;
  }
  .slick-dots {
    width: 100%;
  }
  .slider-wrapper {
    width: 80vw;
    margin: auto;
  }
}

@media (min-width: 992px) {
  .slick-prev,
  .slick-next {
    display: block !important;
  }
}
