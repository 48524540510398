$maets-green: #0d6e39;
//file uploader
.uploadControl {
  display: flex;
  flex-flow: column wrap !important;
}

.thumbnail-wrapper {
  display: flex;
  align-items: center;
  padding: 6px 4px;
}

.thumbnail {
  flex-basis: 0px;
  height: 100%;
  max-width: 50px;
  max-height: 50px;
  object-fit: cover;
}

.thumbnail-caption {
  flex-grow: 1;
  font-size: 14px;
  color: $maets-green;
  margin-bottom: 5px;
  padding: 0 12px;
}

.success-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.success-container h2,
small {
  color: green;
  text-align: center;
}
//file uploader end

.applyWrapper {
  padding: 91px 22px 0;
  h2,
  .h2 {
    font-size: 27px;
    font-weight: 600;
    color: $maets-green;
  }
  p {
    font-size: 14px;
    color: grey;
  }
  .btn-primary,
  .btn-primary:active,
  .btn-primary:not(:disabled):not(.disabled):active {
    background-color: $maets-green;
    color: white;
    margin: 10px 0;
    border: 1px solid transparent;
  }
  .btn-primary:hover {
    background-color: white;
    color: $maets-green;
    border: 1px solid $maets-green;
  }
  .btn-primary:focus {
    color: #fff;
    background-color: #0d6e39;
    border-color: #a2e0be;
    box-shadow: 0 0 0 0.2rem #0d6e3945;
  }
  .btn-primary:not(:disabled):not(.disabled):active:focus {
    color: #fff;
    background-color: #0d6e39;
    border-color: #a2e0be;
    box-shadow: 0 0 0 0.2rem #0d6e3945;
  }

  .apply-label,
  .form-label {
    width: 100%;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 500;
    color: rgb(71, 71, 71) !important;
    span {
      color: $maets-green;
    }
  }

  .apply-input,
  .apply-textarea {
    display: block;
    margin-top: 5px;
    font-size: 1rem;
    width: 100%;
    border: 0.5px solid #d8d3d3;
    border-radius: 2px;
    padding: 10px;
  }

  .form-group {
    margin-bottom: 1rem;
    display: fl ex;
    flex-flow: row wrap;
    align-content: flex-start;
    justify-content: flex-start !important;
    padding: 4px 0px;
  }
  .resultLabel {
    display: inline-block;
    font-weight: 600;
    color: rgb(29, 31, 31);
    padding: 0 10px 10px 10px;
    font-size: 14px;
  }
}
@media (min-width: 768px) {
  .applyWrapper {
    padding: 95px 35px 0;
  }
}
@media (min-width: 992px) {
  .applyWrapper {
    padding: 135px 58px 0;
  }
}
